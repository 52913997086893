<template>
  <el-form ref="form" :model="formData" label-width="120px">
    <el-form-item label="站点名称">
      <el-input style="width: 400px" v-model="formData.name" size="small" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="logo">
      <UploaderImg :url.sync="formData.logo"></UploaderImg>
    </el-form-item>
    <el-form-item label="站点描述">
      <el-input style="width: 400px" type="textarea" v-model="formData.desc" size="small" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="">
      <el-button type="" size="small" @click="getSiteDetail">取 消</el-button>
      <el-button type="primary" size="small" @click="save">保 存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getSiteDetailAPI, saveSiteAPI } from './api'
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'
export default {
  name: 'Site',
  components: { UploaderImg },
  data() {
    return {
      formData: {
        name: '',
        logo: '',
        desc: ''
      }
    }
  },

  mounted() {
    this.getSiteDetail()
  },

  methods: {
    async getSiteDetail() {
      const res = await getSiteDetailAPI('siteinfo')
      this.formData = res.content
    },
    save() {
      const data = {
        type: 'siteinfo',
        content: this.formData
      }
      saveSiteAPI(data).then(() => {
        this.$message.success('保存成功')
        this.getSiteDetail()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
